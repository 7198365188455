<template>
  <div class="card p-1">
    <b-form-datepicker
      v-model="test"
      locale="th"
    />
  </div>
</template>

<script>
import { BFormDatepicker } from 'bootstrap-vue'

export default {
  components: {
    BFormDatepicker,
  },
  data() {
    return {
      test: new Date(),
    }
  },
}
</script>
